.MuiSwitch-root{
    border: 2px solid #999999;
    border-radius: 40px;
    background-color: #E5E5E5;
    height: 10px !important;
    width: 41px !important;
    padding: 9px !important;

    .MuiSwitch-colorSecondary.Mui-checked {
        color: #00BC4B;
    }

    .MuiSwitch-switchBase{
        color: #FFC200;
        padding: 2px;
    }

    .MuiSwitch-thumb{
        height: 14px;
        width: 14px;
    }

    .MuiIconButton-root:hover, .MuiSwitch-colorSecondary.Mui-checked:hover {
        background-color: rgba(0, 0, 0, 0.04);
    }

    .MuiSwitch-track, .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track{
        background-color: transparent;
        opacity: none;
    }
}