.dashboard {
  padding-left: 31px;
  padding-top: 18px;

  .MuiAccordion-root.Mui-expanded {
    margin-bottom: 0px;
  }

  .selectYear {
    background-color: #D6D8DB;
    border-radius: 8.9px;
    box-shadow: 7.34286px 7.34286px 17.6229px rgba(166, 180, 200, 0.7);
    border: none;
    width: 140px;
    font-size: 15px;
    height: 38px;
    padding-left: 10px;
    outline: none;
    padding-top: 6px;
  }

  .dateSelector {
    background-color: #D6D8DB;
    border-radius: 8.9px;
    box-shadow: 7.34286px 7.34286px 17.6229px rgba(166, 180, 200, 0.7);
    border: none;
    color: #000000;
    font-weight: 300;
    font-size: 15px;
    width: 190px;
    margin-left: 20px;
  }

  .gm-ui-hover-effect {
    display: none !important;
  }

  .inputForm {
    background-color: #F2F2F2;
    color: "#8A8B8B";
    border-radius: 8.81px !important;

    .MuiInputBase-formControl {
      border-radius: 8.81px;
    }

    .MuiFormHelperText-root.Mui-error {
      background-color: #ffffff;
      margin: 0;
      padding-top: 5px;
    }
  }

  .inputFormOutCard {
    @extend .inputForm;
    background-color: #D6D8DB;
    color: #808080;
    border-radius: 8.81px !important;

    .MuiFormHelperText-root.Mui-error {
      background-color: #f2f2f2;
    }
  }

  .select__control {
    background-color: #D6D8DB;
    border-radius: 8.9px;
    box-shadow: 7.34286px 7.34286px 17.6229px rgba(166, 180, 200, 0.7);
    border: none;

    .select__indicator-separator {
      background-color: transparent;
    }

    .select__placeholder,
    .select__single-value {
      color: #000000;
      font-weight: 300;
      font-size: 15px;
    }

    .select__indicators svg {
      color: #303E47;
    }
  }



  %selectDataUsers {
    background-color: #D6D8DB;
    border-radius: 8.9px;
    box-shadow: 7.34286px 7.34286px 17.6229px rgba(166, 180, 200, 0.7);
    border: none;
    width: 170px;
  }

  .selectDataUsers__control,
  .selectProvincies__control {

    @extend %selectDataUsers;

    .selectData__indicator-separator {
      background-color: transparent;
    }

    .selectData__placeholder,
    .select__single-value {
      color: #000000;
      font-weight: 300;
      font-size: 15px;
    }

    .selectData__indicators svg {
      color: #303E47;
    }
  }

  .selectProvincies__control {
    right: 240px;
  }

  .selectDataUsersSettings__control {
    @extend %selectDataUsers;
    top: 101px;
    z-index: 10;
    position: fixed;
    right: 411px;
    z-index: 10;
  }
  .select-alert {
    background-color: #D6D8DB;
    border-radius: 8.9px;
    box-shadow: 7.34286px 7.34286px 17.6229px rgba(166, 180, 200, 0.7);
    border: none;
    width: 170px;
  }

  .selectDataJourneyAlerts__control,
  .selectDataJourneyAlerts2__control,
  .selectDataJourneyAlerts3__control {
    background-color: #D6D8DB;
    border-radius: 8.9px;
    box-shadow: 7.34286px 7.34286px 17.6229px rgba(166, 180, 200, 0.7);
    border: none;
    width: 170px;
    margin: 10px;
    // position: absolute;
    // top: 0px;
    // right: 585px;
    z-index: 50;

    .selectData__indicator-separator {
      background-color: transparent;
    }

    .selectData__placeholder,
    .select__single-value {
      color: #000000;
      font-weight: 300;
      font-size: 15px;
    }

    .selectData__indicators svg {
      color: #303E47;
    }
  }

  .selectDataJourneyAlerts2__control {
    @extend .selectDataJourneyAlerts__control;

    // right: 405px;
  }

  .selectDataJourneyAlerts3__control {
    @extend .selectDataJourneyAlerts__control;
    // right: 180px;
    margin-right: 28px;
  }

  .selectDataJourneyAlerts4__control {
    @extend .selectDataJourneyAlerts__control;
    // right: 780px;
  }

  .selectTable__control {
    background-color: #9e9e9e;
    border-radius: 8.9px;
    border: none;

    .selectTable__indicator-separator {
      background-color: transparent;
    }

    .selectTable__indicator {
      padding: 0 8px;
    }

    .selectTable__placeholder,
    .selectTable__single-value {
      color: #FFFFFF;
      font-weight: 300;
      font-size: 14px;
    }

    .selectTable__indicators svg {
      color: #FFFFFF;
      //padding: 0 8px;
    }

    .selectTable__value-container {
      height: -webkit-fill-available;
      margin-top: -5px;
    }
  }

  .selectJourneyAlert__control {
    background-color: #D6D8DB;
    border-radius: 8.9px;
    box-shadow: 7.34286px 7.34286px 17.6229px rgba(166, 180, 200, 0.7);
    border: none;
    width: 170px;
    position: fixed;
    top: 110px;
    right: 50px;
    z-index: 50;

    .selectJourneyAlert__indicator-separator {
      background-color: transparent;
    }

    .selectJourneyAlert__placeholder,
    .selectJourneyAlert__single-value {
      color: #000000;
      font-weight: 300;
      font-size: 15px;
    }

    .selectJourneyAlert__indicators svg {
      color: #303E47;
    }
  }

  .dashboardButton {
    border: none;
    box-sizing: border-box;
    box-shadow: 7.34286px 7.34286px 17.6229px rgba(166, 180, 200, 0.7);
    border-radius: 10px;
    font-size: 15px;
    color: #FFFFFF;
    font-weight: 600;
    padding: 0 15px;
  }

  .accordion {
    background-color: transparent;
    box-shadow: none;
    margin-bottom: 10px !important;

    .MuiAccordion-root.Mui-expanded {
      margin-bottom: 0px;
    }

    .accordionHeader {
      background-color: #D2D4D8;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-left: 0px;

      .MuiAccordionSummary-content {
        align-items: center;
      }

      .MuiAccordionSummary-content.Mui-expanded {
        margin: 0px
      }

      .playIcon {
        margin-left: -7px;
        margin-right: 10px;
        height: 30px;
        width: 30px;
        color: #7C7C7C;
      }

      .HeaderIcon {
        color: #7C7C7C;
        height: 30px;
        width: 40px;
        margin-right: 15px;
      }

      .numberAlerts {
        height: 30px;
        width: 40px;
        background-color: red;
        border-radius: 20px;
        align-items: center;
        justify-content: center;
        display: flex;
        color: white;
        font-weight: 600;
        font-size: 15px;
        margin-right: 15px;
      }

      .nameAccordion {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: -webkit-fill-available;
        justify-content: space-between;
      }
    }

    .accordionBody {
      display: flex;
      flex-direction: column;
      padding: 16px 8px 0px 8px;

      .rowArowAccordionAlertBody {
        width: 100%;
        border-bottom: 1px solid #000000;
        padding-bottom: 3px;
        padding-left: 10px;
        padding-top: 15px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &:first-child {
          padding-top: 10px;
        }

        h6 {
          color: #4B545A;
          font-weight: 600;
          font-size: 14px;

          &:first-child {
            display: flex;
            flex-direction: row;
          }

          &:last-child {
            font-weight: 300;
          }

          .icon {
            color: #000000;
            width: 30px;
            height: 30px;
            margin-right: 10px;
            margin-top: -5px;
          }
        }
      }
      .rowArowAccordionAlertBody2 {
        width: 100%;
        border-bottom: 1px solid #000000;
        padding-bottom: 3px;
        padding-left: 10px;
        padding-top: 15px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &:first-child {
          padding-top: 10px;
        }

        h6 {
          color: #4B545A;
          font-weight: 600;
          font-size: 14px;

          &:first-child {
            display: flex;
            flex-direction: row;
          }

         

          .icon {
            color: #000000;
            width: 30px;
            height: 30px;
            margin-right: 10px;
            margin-top: -5px;
          }
        }
      }

      .rowAccordionFrequentBody {
        width: 100%;
        border-bottom: 1px solid #000000;
        padding-bottom: 3px;
        padding-left: 50px;
        padding-top: 20px;

        &:first-child {
          padding-top: 5px;
        }

        h6 {
          color: #4B545A;
          font-weight: 600;
          font-size: 14px;

          &:last-child {
            font-weight: 300;
          }
        }
      }

      .rowArowAccordionTopBody {
        width: 100%;
        border-bottom: 1px solid #000000;
        padding-bottom: 3px;
        padding-left: 20px;
        padding-top: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;

        &:first-child {
          padding-top: 10px;
        }

        .icon {
          color: #000000;
          width: 30px;
          height: 30px;
          margin-right: 10px;
          margin-top: -5px;
        }

        .contentTop {
          display: flex;
          flex-direction: column;
          width: 100%;
          padding-right: 20px;

          h6 {
            color: #4B545A;
            font-weight: 600;
            font-size: 14px;
            margin-bottom: 5px;
          }

          div {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            h6 {
              font-weight: 300;
            }
          }
        }
      }
    }
  }
  .MuiInputLabel-outlined {
    z-index: 0;
  }
}
