.authorizations {
    padding-left: 31px;
    padding-top: 18px;
    display: flex;
    flex-direction: row;

    .col__left{
        padding-left: 0px;

        form{
            margin-top: 30px;
            padding-left: 20px;
            padding-right: 15px;

            .form-group{
                align-items: center;

                label{
                    font-weight: 600;
                    color: #4B545A;
                }

                .AuthorizationsInput{
                    background: #D6D8DB;
                    border: 0.353659px solid #FEFEFF;
                    box-sizing: border-box;
                    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.05);
                    border-radius: 11.3171px;
                    color: #383838;
                    font-weight: 300;
                    font-size: 14px;
                    height: 40px;
                    padding-left: 20px;

                    &::placeholder{
                        color: #383838;
                        font-weight: 300;
                        font-size: 14px;
                    }
                }
            }
        }

        .authorizationRequested{
            margin: 0;
            height: 50%;
            display: flex;
            flex-direction: column;
            margin-top: 50px;
            margin-bottom: 20px;

            .authorizationsContainer{
                display: flex;
                flex-direction: column;
                height: auto;

                .authorizationContainer{
                    display: flex;
                    align-items: center;
                    padding: 15px 0px 15px 10px;
                
                    .authorizationText{
                        margin-right: 10px;

                        h6{
                            font-size: 12px;
                        
                            &:last-child{
                                font-size: 10px;
                                margin-top: 3px;
                            }
                        }
                    }

                    .authorizationButtons{
                        display: flex;

                        button{
                            border: 0.367143px solid #FEFEFF;
                            box-sizing: border-box;
                            box-shadow: 7.34286px 7.34286px 17.6229px rgba(166,180,200, 0.7);
                            border-radius: 8.81143px;
                            font-size: 12px;
                            color: #FFFFFF;
                            font-weight: 600;
                            width: 100px;
                            height: 35px;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                        &:first-child{
                            background: #3DBE00;
                            margin-right: 15px;
                        }

                        &:last-child{
                            background: #EC1C24;
                        }
                        }
                    }
                }
            }
        }
    }

    .col__right{
        padding-left: 0px;
        min-height: calc(100vh - 127px);
        border-left: 1px solid #000000;
        margin-top: 27px;

        .authorizedRequests{
            margin: 0;
            height: 100%;
            display: flex;
            flex-direction: column;
            padding: 5px 0px 0px 24px;

            .authorizationsContainer{
                display: flex;
                flex-direction: column;
                overflow: auto;
                height: 80%;
                padding-left: 20px;
                padding-top: 5px;

                .authorizationContainer{
                    display: flex;
                    align-items: center;
                    margin-bottom: 15px;
                
                    .authorizationText{
                        margin-right: 40px;

                        h6{
                            font-size: 16px;
                        
                            &:last-child{
                                font-size: 10px;
                                margin-top: 3px;
                            }
                        }
                    }
                }
            }
        } 
    }
}