.JourneyAlertDetails {
    //width: 650px;
    padding-top: 21px;

    .profileUserAlert{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;
        margin-right: 20px;
        max-width: 650px;

        img{
            height: 150px;
            width: 150px;
            border-radius: 100px;
        }

        .profileData{
            padding-left: 20px;

            h6{
                color: #4B545A;
                font-weight: 300;
                font-size: 16px;
                margin-bottom: 10px;
            }
            
            .company{
                font-weight: 600;
            }

            .name{
                color: #1A0C12;
                font-weight: 600;
                font-size: 25px;
            }

            .job{
                font-weight: normal;
            }
        }
    }

    .lastRoutes{
        padding-left: 10px;

        .listItems{
            h6{
                margin-bottom: 10px;
                font-size: 13px;
            }
        }
    }

    .descriptionAlert{
        display: flex;
        flex-direction: row;
        align-items: baseline;
        justify-content: space-between;
        padding-right: 30px;

        .dataDescriptionAlert{
            margin-left: 15px;

            h5{
                color: #1A0C12;
                font-weight: 600;
                font-size: 25px;
                margin-top: 5px;

                .carICon{
                    color: #999999;
                    height: 40px;
                    width: 40px;
                    margin-right: 10px;
                }
            }

            h6{
                font-size: 14px;
                color: #4B545A;
                padding-bottom: 10px;
            }
        }
    }
}