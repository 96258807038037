.account {
  height: 100vh;
  width: 100%;
  min-height: 100vh;
  display: flex;
  overflow-y: auto;

  @include themify($themes) {
    background: themed('colorBackgroundTopBar');
  }

  .form__form-group-field{
    background-color: #FEFEFF;
    border: 0.353659px solid #FEFEFF;
    border-radius: 11.32px;
    box-shadow: inset 3.53659px 3.53659px 7.07317px rgba(166, 180, 200, 0.75), inset -3.53659px -3.53659px 10.6098px rgba(255, 255, 255, 0.75);
    padding: 2px 5px;

    .form__form-group-icon{
      background-color: transparent;
      border: none;
      padding: 2px 6px;

      & svg{
        height: 25px;
        width: 25px;
      }
    }

    input{
      border:none;
    }

    .form__form-group-button{
      background-color: transparent;
      border: none;
    }
  }

  .account__btn{
    background-color: #E8272C;
    box-shadow: -12px -12px 20px rgba(57, 57, 57, 0.8), 10px 10px 24px rgba(27, 29, 30, 0.73);
    border-radius: 11.3171px;
    border: none;

    &:before {
      background-color: #ad0309 !important;
    }
  }

  .recoverPassword{
    color: #878C90;
    font-weight: 200;
  }
}

.account__wrapper {
  margin: auto;
  padding: 10px;
}

.account__card {

  @include themify($themes) {
    background-color: themed('colorBackgroundTopBar');
  }

  background-color: #ffffff;
  max-width: 300px;
  width: 100%;
}

.account__profile {
  text-align: center;
}

.account__btns {
  display: flex;
  width: calc(100% + 10px);
  margin: -10px 0 -20px -10px;

  a {
    margin: 10px 0 20px 10px;
    white-space: nowrap;
  }
}

.account__btn {
  width: 100%;
  margin-right: 0;
}

.account__avatar {
  height: 64px;
  width: 64px;
  border-radius: 50%;
  overflow: hidden;
}

.account__name {
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 15px;
  margin-top: 5px;
}

.account__sub {
  margin-top: 0;
  margin-bottom: 10px;
  color: $color-additional;
  font-size: 11px;
  line-height: 15px;
}

.account__forgot-password {
  position: absolute;
  font-size: 11px;
  line-height: 15px;
  bottom: -18px;
  right: 0;

  a {
    color: $color-blue;

    &:hover {
      color: $color-blue-hover;
      text-decoration: none;
    }
  }
}

.form__form-group--forgot {
  margin-bottom: 40px;
}

.account__or {
  text-align: center;
  margin-top: 35px;
  margin-bottom: 20px;
  position: relative;

  &:before, &:after {
    content: "";
    height: 1px;
    width: calc(50% - 90px);
    background: #dddddd;
    position: absolute;
    top: 10px;
  }

  &:before {
    left: 0;
  }

  &:after {
    right: 0;
  }
}

.account__social {
  text-align: center;
}

.account__social-btn {
  display: inline-block;
  height: 38px;
  width: 38px;
  border-radius: 5px;
  padding: 9px;
  margin-right: 10px;
  transition: all 0.3s;
  border: none;

  &:last-child {
    margin-right: 0;
  }

  svg {
    fill: #ffffff;
    height: 20px;
    width: 20px;
  }

  &.account__social-btn--facebook {
    background: #4766a4;

    &:hover {
      background: darken(#4766a4, 10%);
    }
  }

  &.account__social-btn--google {
    background: #c74d4d;

    &:hover {
      background: darken(#c74d4d, 10%);
    }
  }
}

.account__head {
  margin-bottom: 30px;
  padding-left: 10px;
  border-left: 4px solid $color-blue;
}

.account__logo {
  font-weight: 700;
}

.account__logo-accent {
  color: $color-blue;
}

.account__have-account {
  text-align: center;
  margin-top: 20px;

  a {
    color: $color-blue;
    transition: all 0.3s;

    &:hover {
      color: $color-blue-hover;
      text-decoration: none;
    }
  }
}

@media screen and (max-width: 520px) {

  .account__card {
    padding: 35px 30px;
  }
}

@media screen and (max-width: 425px) {

  .account__btns {
    flex-wrap: wrap;
  }
}

.account__btn__loading {
  cursor: not-allowed;
  background-color: #E8272C;
  //margin: auto;
  margin: 0 auto 20px auto;
  border-radius: 50px;
  border: none;
  padding: 10px 25px;
  font-size: 14px;
  height: 41px;
  padding: 5px;
  animation: changeWidthButton 0.5s linear;
}

@keyframes changeWidthButton {
  from { width: 100% }
  to  { width: 10% }
}

.spinner__btn__loading{
  color: white;
}