.p-checkbox .p-checkbox-box.p-highlight {
    border-color: var(--primary);
    background: var(--primary);
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    border-color: var(--primary);
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
    border-color: var(--primary);
    box-shadow: 0 0 0 0.2rem var(--blue-100);
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    border-color: var(--primary);
    background-color: var(--blue-600);
    box-shadow: 0 0 0 0.2rem var(--blue-100);
}

.p-datatable .p-datatable-tbody > tr.p-highlight {
    background: var(--blue-50);
    color: var(--primary);
}

.p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd.p-highlight {
    background: var(--blue-100);
    color: var(--primary);
}

.p-datatable .p-sortable-column:focus {
    box-shadow: inset 0 0 0 0.15rem var(--blue-100);
    outline: 0 none;
}
.p-datatable .p-sortable-column.p-highlight {
    background:  var(--blue-100);
    color: var(--primary);
}

.p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover {
    background: #EEF2FF;
    color: var(--primary);
}

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: var(--primary);
}

.p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover .p-sortable-column-icon {
    color: var(--primary);
}


.p-inputtext:enabled:hover {
    border-color: var(--primary);
}

.p-inputtext:enabled:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem var(--blue-100);
    border-color: var(--primary);
}

.p.multiselect:not(.p-disabled).p-focus {
    border-color: var(--primary);
    box-shadow: 0 0 0 0.2rem var(--blue-100);
}

.p-multiselect:not(.p-disabled):hover {
    border-color: var(--primary);
}


.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
    color: var(--primary);
    background: var(--blue-50);
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem var(--blue-100);
}


.p-column-filter-menu-button.p-column-filter-menu-button-active, .p-column-filter-menu-button.p-column-filter-menu-button-active:hover {
    background: #EEF2FF;
    color: var(--primary)
}


.p-column-filter-menu-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem var(--blue-100);
}

.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item.p-highlight {
    color: var(--primary);
    background: var(--blue-100)
}


.p-column-title {
    color: #D8394C;
    font-size: 16px;
    text-transform: capitalize;
}

.p-sortable-column-icon{
    color: #D8394C !important;
}
.p-column-filter-menu-button{
    display: none !important;
}