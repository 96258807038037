.container {

  @media screen and (min-width: 768px) {
    width: 100%;
    max-width: 1630px;
  }
}

.container__wrap {
  padding-left: 0;
  padding-top: 80px;
  min-height: 100vh;
  transition: padding-left 0.3s;
  background: $color-light;
  

  @media screen and (min-width: 576px) {
    padding-left: 240px;
  }
}

.layout {

  &.layout--collapse {

    & + .container__wrap {
      padding-left: 0;

      @media screen and (min-width: 576px) {
        padding-left: 60px;
      }
    }
  }

  &.layout--top-navigation{

    & + .container__wrap{

      @media screen and (min-width: 576px) {
        padding-left: 0;
      }
    }
  }
}

.breadcrums{
  margin: 10px 0px 0px 20px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

}

.breadcrumb__separator {
  margin: 0 8px;
}

.item-breadcrum {
  color: grey;
  cursor: pointer;
}
.item-breadcrum:hover {
  text-decoration: underline;
  color: black;
}

.current-link{
  color: black;
  font-weight: bold;
}

.map{
  height: 95% !important;
  position: absolute !important;
}