.userProfile {
    padding-left: 31px;
    padding-top: 18px;

    .selectYear {
        background-color: #D6D8DB;
        border-radius: 8.9px;
        box-shadow: 7.34286px 7.34286px 17.6229px rgba(166, 180, 200, 0.7);
        border: none;
        width: 90px;
        font-size: 15px;
        height: 35px;
        padding-left: 10px;
        outline: none;
        padding-top: 6px;
    }

    .title {
        display: flex;
        flex-direction: row;
        padding-top: 10px;

        .iconTitle {
            height: 35px;
            width: 45px;
            color: #4B545A;
            margin-right: 10px;
            margin-top: -5px;
        }
    }

    .buttons {
        display: flex;
        flex-direction: column;

        button,
        .link {
            width: 170px;
            margin-bottom: 10px;
            border: none;
            box-sizing: border-box;
            box-shadow: 7.34286px 7.34286px 17.6229px rgba(166, 180, 200, 0.7);
            border-radius: 8.81143px;
            font-size: 14px;
            background-color: #EC1C24;
            color: #FFFFFF;
            font-weight: 500;
            padding: 5px 5px;
            text-align: center;
        }
    }

    .contentUser {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin-top: 10px;

        .leftColumn {
            display: flex;
            //width: 30%;

            img {
                height: 100px;
                width: 100px;
                border-radius: 100px;
            }

            .separator {
                height: 100px;
                width: 1px;
                background-color: #969696;
                margin: 0 10px;
            }

            .profileData {
                height: 100px;
                display: flex;
                flex-direction: column;
                justify-content: space-around;

                h6 {
                    color: #4B545A;
                    font-weight: 300;
                    font-size: 14px;
                }

                .company {
                    font-weight: 600;
                }

                .name {
                    color: #1A0C12;
                    font-weight: 600;
                    font-size: 20px;
                }

                .job {
                    font-weight: normal;
                }
            }
        }

        .rightColumn {
            // width: 70%;

            .numbers {
                background-color: #fff;
                border-radius: 13px;
                border: 1px solid #C4C4C4;
                padding: 15px 30px;

                .number {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    flex-wrap: wrap;

                    .numbersHeader {
                        color: #4B545A;
                        font-size: 14px;
                        font-weight: 600;

                        .iconHeader {
                            color: #4B545A;
                            height: 15px;
                            width: 15px;
                            margin-top: -3px;
                        }
                    }

                    h6 {
                        font-size: 14px;

                        &:first-child {
                            margin-bottom: 10px;
                        }
                    }
                }
            }

            .linkUser {
                width: 170px;
                margin-bottom: 10px;
                border: none;
                box-sizing: border-box;
                box-shadow: 7.34286px 7.34286px 17.6229px rgba(166, 180, 200, 0.7);
                border-radius: 8.81143px;
                font-size: 14px;
                background-color: #EC1C24;
                color: #FFFFFF;
                font-weight: 500;
                padding: 5px 5px;
                text-align: center;
            }

            .data {
                background-color: #fff;
                margin-top: 10px;
                border-radius: 13px;
                border: 1px solid #C4C4C4;
                padding: 10px 10px;

                .header {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0 15px;
                    margin-bottom: 10px;

                    button {
                        border: none;
                        box-sizing: border-box;
                        box-shadow: 7.34286px 7.34286px 17.6229px rgba(166, 180, 200, 0.7);
                        border-radius: 8.81143px;
                        font-size: 14px;
                        background-color: #EC1C24;
                        color: #FFFFFF;
                        font-weight: 500;
                        padding: 5px 15px;
                    }
                }

                .item {
                    display: flex;

                    .cellHeader {
                        width: 100%;

                        .cell {
                            display: flex;
                            flex-wrap: wrap;
                            width: 100%;
                            border-bottom: 1px solid #C4C4C4;
                            padding: 5px 0px;
                        }

                        h6 {
                            color: #4B545A;
                            font-size: 14px;
                            padding-left: 35px;

                            &:first-child {
                                width: 210px;
                                font-weight: 600;
                            }
                        }


                    }
                }
            }

            .observations {
                background-color: #fff;
                margin-top: 10px;
                border-radius: 13px;
                border: 1px solid #C4C4C4;
                padding: 10px 10px;
                height: 30vh;
                margin-bottom: 15px;
                overflow: auto;

                .cell {
                    display: flex;
                    width: 100%;
                    border-bottom: 1px solid #C4C4C4;
                    padding: 5px 0px;
                    font-size: 14px;
                    font-weight: normal;
                    padding-left: 25px;
                }
            }
        }

        .dataUser {
            background-color: #fff;
            margin-top: 10px;
            border-radius: 13px;
            border: 1px solid #C4C4C4;
            padding: 10px 10px;

            .header {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                padding: 0 5px;
                margin-bottom: 10px;
            }

            .item {
                display: flex;

                .cellHeader {
                    width: 100%;

                    .cell {
                        display: flex;
                        width: 100%;
                        border-bottom: 1px solid #C4C4C4;
                        padding: 5px 0px;
                        .link_cell{
                            color:red
                        }
                    }

                   

                    

                    h6 {
                        color: #4B545A;
                        font-size: 14px;
                        padding-left: 15px;
                        overflow: hidden;

                        &:first-child {
                            width: 150px;
                            font-weight: 600;
                        }
                    }
                }
            }
        }

        .bodyReport {
            .header {
                display: flex;
                width: 100%;
                border-bottom: 1px solid #C4C4C4;
                padding: 5px 0px;
                font-weight: normal;
                padding-left: 25px;

                h6 {
                    font-weight: 600;
                    font-size: 15px;
                    color: #4B545A;
                }
            }

            .body {
                @extend .header;
                align-items: center;

                h6 {
                    font-size: 14px;
                    font-weight: 400;
                    color: #4B545A;
                }
            }
        }
    }
}