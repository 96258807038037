.btn-container{
    background-color: #D8394C;
    width: auto;
    padding: 10px 15px ;
    border-radius: 8px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color: white;
    border: 0px;
    font-size: 14px;
}

.btn-container:hover{
    background-color: #d8394cdc;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.btn-container:disabled {
    background-color: #9E9E9E;
}