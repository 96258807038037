.generalReportTable{
    
    .contentReport{
        width: 100%;
        border-top: 1px solid #000000;
        padding-bottom: 30px;

        .headerReport{
            margin-top: 10px;

            .cell{
                display: flex;
                width: 100%;
                border-bottom: 1px solid #C4C4C4;
                padding: 10px 0px;
            
                h6{
                    color:#4B545A;
                    font-size: 14px;
                    padding-left: 5px;

                    &:first-child{
                        width: 200px;
                        font-weight: 600;
                    }
                }
            }
            
        }

        .bodyReport {
            margin-top: 20px;
            max-width: 100%;

            .Header{
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 10px 10px;
                border-bottom: 1px solid #969696;

                h6{
                    color: $color-dark-red;
                    font-size: 14px;
                    font-weight: 600;
                    text-align: center;
                
                    &:first-child{
                        text-align: left;
                    }
                }
            }

            .driverCell{
                background-color: #E5E5E5;
                padding: 7px 10px;
                margin-top: 5px;

                h6{
                    color: #3F3838;
                    font-weight: 300;
                    font-size: 14px;
                }
            }

            .cell{
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 7px 10px;
                //border-bottom: 1px solid #969696;
                justify-content: space-between;

                h6{
                    color: #3F3838;
                    font-weight: 300;
                    font-size: 14px;
                    text-align: center;
                
                    &:first-child{
                        text-align: left;
                    }
                }
            }

            .cellTotal{
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 7px 10px;

                h6{
                    color: #3F3838;
                    font-weight: 300;
                    font-size: 14px;
                    text-align: center;
                    width: 10%;

                    &:first-child{
                        font-weight: bold;
                        color: #3F3838;
                        text-align: end;
                        width: 90%;
                    }
                }
            }

            .total{
                margin-top: 30px;

                .itemValue{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 7px 10px;
                    background-color: #E5E5E5;
                    
                    h6{
                        color: #3F3838;
                        font-weight: 300;
                        font-size: 14px;
                        text-align: center;
                        width: 10%;
    
                        &:first-child{
                            font-weight: bold;
                            color: $color-dark-red;
                            text-align: end;
                            width: 90%;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 600px) {
    .bodyReport {
        overflow: scroll;
    }
  }